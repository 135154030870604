<template>
	<div>
		<pui-datatable
			:modelName="model"
			:actions="actions"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:isEditableRegistry="isEditableRegistry"
			:modelColumnDefs="modelColumnDefs"
			:modalDialog="modalDialog"
			:navigableDetail="false"
			readOnly
		></pui-datatable>
	</div>
</template>

<script>
import documentosaduaneroequipamientoActions from './DocumentosaduaneroequipamientoActions';
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'documentosaduaneroequipamiento-grid',
	mixins: [PuiGridDetailMixin],
	components: {},
	data() {
		return {
			model: 'documentosaduaneroequipamiento',
			actions: documentosaduaneroequipamientoActions.gridactions,
			modelColumnDefs: {
				estado: {
					createdCell: (td, cellData, rowData) => {
						if (td.parentNode) {
							if (rowData.indposestado === 'V') {
								td.parentNode.classList.add('lsppositivo');
							} else if (rowData.indposestado === 'R') {
								td.parentNode.classList.add('lspnegativo');
							}
						}
					}
				}
			}
		};
	},
	methods: {
		isEditableRegistry() {
			return false;
		}
	}
};
</script>

<style lang="postcss">
.lspnegativo {
	color: rgb(169, 68, 66);
	background-color: rgba(236, 193, 193, 0.8) !important;
	//font-weight: bold;
}
.lsppositivo {
	color: rgb(4, 68, 2);
	background-color: rgba(193, 243, 184, 0.8) !important;
	//font-weight: bold;
}
</style>
